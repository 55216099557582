var slick = require('../vendor/slick.min.js');
require('jquery-ui');

$.fn.isInViewport = function() {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
};


$(document).ready(function () {
    // Slider settings
    // const prevArw = '<button type="button" class="navi navi-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></button>';
    // const nextArw = '<button type="button" class="navi navi-next"><i class="fa fa-angle-right" aria-hidden="true"></i></button>';
    var imgurl = '../assets/images/icons/nav-left.png';
    const prevArw = '<button type="button" class="navi navi-prev"></button>';
    const nextArw = '<button type="button" class="navi navi-next"></button>';
    var options = {
        slidesToShow: 1,
        dots: true,
        infinite: false,
        speed: 800,
        fade: true,
        dots: true,
        prevArrow: prevArw,
        nextArrow: nextArw,
        autoplay: true,
    }
    var options3 = {
        dots: true,
        speed: 800,
        infinite: true,
        centerMode: true,
        centerPadding: '80px',
        slidesToShow: 3,
        prevArrow: prevArw,
        nextArrow: nextArw,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                centerMode: false,

                dots: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                centerMode: true,
            }
        },
        {
            breakpoint: 480,
            settings: {
                centerPadding: '0',
                slidesToShow: 1,
                centerMode: false,
            }
        }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]

    }

    //
    // Header slider initialization
    //
    if ($('.header-slider').length) {
        $('.header-slider').slick(options);
    }

    //
    // Slider component initialization
    //
    if ($('.slider-component-init').length) {
        $('.slider-component-init').each(function (idx, item) {
            if ($(this).find('article').length > 1) {
                $(this).slick(options);
            }
        });
    }

    if ($('.rooms-slider-component-init').length) {
        $('.rooms-slider-component-init').each(function (idx, item) {
            if ($(this).find('article').length > 1) {
                $(this).slick(options3);
            }
        });
    }


    //
    // Slider list (clients list) initialization
    //
    const options2 = {
        slidesToShow: 6,
        slidesToScroll: 3,
        dots: false,
        infinite: false,
        speed: 800,
        fade: false,
        prevArrow: prevArw,
        nextArrow: nextArw,
        responsive: [{
            breakpoint: 1201,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 993,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                //dots: true
            }
        },
        {
            breakpoint: 577,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                //dots: true
            }
        },
        {
            breakpoint: 421,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                //dots: true
            }
        }
        ]
    }

    if ($('.slider-list-init').length) {
        $('.slider-list-init').slick(options2);
    }

    //
    // References slider initialization
    //
    if ($('.slider-block-init').length) {
        //options.fade = false;
        $('.slider-block-init').slick(options);
    }


    //
    // Social share popup activation
    //
    $(document).on('click', '.social-popup-trigger', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var targetEl = $(this).parent().find('.social-popup');
        targetEl.toggleClass('active');
        if (targetEl.hasClass('on')) {
            targetEl.removeClass('on');
        } else {
            setTimeout(function () {
                targetEl.addClass('on');
            }, 100);
        }
    });

    $(document).on('click', '#share-icon', function (e) {
        e.preventDefault();
        e.stopPropagation();
        console.log('test click');
        var tarEl = $(this).parent().find('.social-icon-popup');
        tarEl.toggleClass('active');
        if (tarEl.hasClass('on')) {
            tarEl.removeClass('on');
        } else {
            setTimeout(function () {
                tarEl.addClass('on');
            }, 100)
        }
    });

    //
    // Anchor link scrollTop animation
    //
    $(document).on('click', '.anchor', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var id = $(this).attr('href');
        if ($(id).length) {
            $('html, body').animate({
                scrollTop: $(id).offset().top - 90
            });
        }
    });

    //
    // Comparison table responsive view
    //
    var products = $('.comparison-table .col-product');
    var tabs = $('#productTabs .nav-link');
    var window_correction = 17;

    function setActiveProduct() {
        if ($(window).width() < 769 - window_correction) {
            products.addClass('tab-pane fade');
            products.removeClass('show active');
            products.first().addClass('show active');
            tabs.removeClass('show active');
            tabs.first().addClass('show active');
        } else {
            products.removeClass('tab-pane fade show active');
            tabs.removeClass('show active');
        }
    }

    setActiveProduct();

    //
    // Set equal row height
    //

    function setEqualRowHeight(el, el2) {
        if ($(window).width() > 768) {
            var h;
            var arr = [];
            $(el).each(function (i) {
                h = $(this).height();
                arr.push(h);
            });

            $(el2).each(function () {
                $(this).find('li').each(function (i) {
                    $(this).height(arr[i]);
                });
            });
        }
    }

    setEqualRowHeight('.col-feature li', '.col-product');

    //
    // Navigation animation
    //
    var menu = $(document).find('[data-menu]').attr('data-menu');
    $(document).on('click', '[data-menu]', function (e) {
        e.preventDefault();
        $(menu).toggleClass('open');
        $(menu).find('.main-navigation').toggleClass('show');
        $('.nav-backdrop').toggleClass('show');
        $('body').toggleClass('menu-is-open');
    });

    //
    // Cookie set and link action
    //
    var cookie_name = 'seenCookieMessage';
    //CookieMonster.eraseCookie(cookie_name);
    /*$(document).on('click', '#btnCookie', function(e)  {
        e.preventDefault();
        e.stopPropagation();
        CookieMonster.setCookie(cookie_name, 'yes', 60);
        $('.cookie-component').addClass('close-cookie');
    });*/

    //
    // Close popups(...) when clicked outside of target element
    //
    $(document).on('click', function () {
        $('.social-popup').removeClass('active on');
    });

    //  Back to top anchor
    var bktt = $('#back_to_top');
    bktt.on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: 0
        }, 800);
    });

    // Back to top animation appearance on scroll
    $('body').on('scroll', function (e) {
        console.log(e, $(this));
        if ($(this).scrollTop > 400) {
            bktt.addClass('animate-in');
        } else {
            bktt.removeClass('animate-in');
        }
    });

    // All window resize functions go here
    if ($(window).width() > 769 - window_correction) {
        $(window).resize(function () {
            setActiveProduct();
            setEqualRowHeight('.col-feature li', '.col-product');
        });
    }

    // On tab select animation (the selected tab animates)
    $('#productTabs li').on('click', function () {
        var scroll = 0;
        if ($(this).offset().left > $(window).width() / 2) {
            scroll = $('#productTabs').scrollLeft() + $(this).width();
        } else {
            scroll = $('#productTabs').scrollLeft() - $(this).width();
        }

        $(this).parents('.nav-tabs').animate({
            scrollLeft: scroll
        }, 800);
    });

});

$(window).on('scroll', function () {
    if (!Math.trunc) {
        Math.trunc = function(v) {
            v = +v;
            if (!isFinite(v)) return v;
            
            return (v - v % 1)   ||   (v < 0 ? -0 : v === 0 ? v : 0);
            
            // returns:
            //  0        ->  0
            // -0        -> -0
            //  0.2      ->  0
            // -0.2      -> -0
            //  0.7      ->  0
            // -0.7      -> -0
            //  Infinity ->  Infinity
            // -Infinity -> -Infinity
            //  NaN      ->  NaN
            //  null     ->  0
        };
    }

    // back to top button on scroll
    var s = $(window).scrollTop(),
        d = $(document).height(),
        c = $(window).height();

    var scrollPercent = Math.trunc((s / (d - c)) * 100);
    // If Scroll is > 50 show back_to_top btn, 
    if (scrollPercent >= 50) {
        setTimeout(function () {
            $('#back_to_top').removeClass('animate-out');
            $('#back_to_top').addClass('animate-in');
        }, 500)
    }
    // if scroll is < 10 hide back_to_top btn
    if (scrollPercent <= 10) {
        setTimeout(function () {
            $('#back_to_top').removeClass('animate-in');
            $('#back_to_top').addClass('animate-out');
        }, 500)
    }
});
// YouTube custom play button
// Fades out cover image and button on click and starts video that is behind button and/or image
$(document).on('click', '#video-play-lazy', function (e) {
    e.preventDefault();
    var _this = $(this);
    $(this).fadeOut('normal');
    $(this).parent().find('img').fadeOut('normal');
    var iframe = document.createElement('iframe');
    var embed = $(this).parent().data('embed');
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("allowfullscreen", "");
    iframe.setAttribute("src", "https://www.youtube.com/embed/" + embed + "?enablejsapi=1&version=3&playerapiid=ytplayer");
    iframe.setAttribute('width', '100%');
    iframe.setAttribute('height', '100%');
    $(this).parent().append(iframe);
    setTimeout(function () {
        _this.parent().find('iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
    }, 500);
});
$(document).on('click', '#video-play-normal', function (e) {
    e.preventDefault();
    var _this = $(this);
    $(this).fadeOut('normal');
    $(this).parent().find('img').fadeOut('normal');
    $(this).parent().find('iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');

});

// pause video on click
$(document).on('click', '.navi', function (e) {
    e.preventDefault();
    for (var i = 0; i < $(this).parent().find('iframe').length; i++) {
        $(this).parent().find('iframe')[i].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
    }
});

// reservebutton
$(document).on('click', '#reserve-btn', function (e) {
    e.preventDefault();
    $("#dropdown-reserve").show();
});
$(document).on('click', '#dropdown-form-close', function (e) {
    e.preventDefault();
    $("#dropdown-reserve").hide();
});
$(document).on('click', '#mobile-reserve-btn', function (e) {
    e.preventDefault();
    $("#mobile-dropdown-reserve").show();
});

$(document).on('click', '#mobile-dropdown-form-close', function (e) {
    e.preventDefault();
    $("#mobile-dropdown-reserve").hide();
});

$(function () {
    $("#check_in_date").datepicker();
    $("#check_out_date").datepicker();
    $("#check_in_date_mobile").datepicker();
    $("#check_out_date_mobile").datepicker();
});



