var addThis = (function() {
  var executed = false;
  return function() {
      if (!executed) {
          executed = true;
          $('footer').after('<script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5c64076e03fef37a" async="async"></script>');    
      }
  };
})();

var weatherWidget = (function(){
  var executed = false;
  return function(){
    if (!executed){
      executed = true;
      $('footer').after("<script>!function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src='https://weatherwidget.io/js/widget.min.js';fjs.parentNode.insertBefore(js,fjs);}}(document,'script','weatherwidget-io-js');</script>");
  
    }
  }
})();
$(window).on('resize scroll load', function() {
  if ($('footer').isInViewport()) {
      addThis();
      weatherWidget();
  }
});
